import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  // createRoutesFromElements as Creates,
  // createBrowserRouter as Router,
} from "react-router-dom";

/************************************** */
const ShowCase = lazy(() => import("./components/showcase/ShowCase.js"));
const Events = lazy(() => import("./components/events/Events.js"));
const Application = lazy(() =>
  import("./components/application/Application.js"),
);
const UpSkilling = lazy(() => import("./components/upskilling/UpSkilling.js"));
const LaunchPad = lazy(() => import("./components/launchpad/LaunchPad.js"));
const StreetWise = lazy(() => import("./components/streetwise/StreetWise.js"));

const NagivationTopMenu = lazy(() =>
  import("./components/navigation/navigation-top-menu/NagivationTopMenu.js"),
);
const MobileMenu = lazy(() =>
  import("./components/navigation/mobileMenu/MobileMenu.js"),
);
const Suitable = lazy(() => import("./components/suitable/Suitable.js"));
const ContactForm = lazy(() => import("./components/forms/ContactForm.js"));

/*******************************
 * App
 *******************************/
function App() {
  const [pwa, setPwa] = useState(null);
  const [mobileDeviceMedia, setMobileDeviceMedia] = useState(false);

  // browser media querie and pwa prompt installation
  useEffect(() => {
    let isMounted = true;
    if (!pwa && isMounted) {
      window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        console.log("before installation prompt fired");
        let installPrompt = event;
        setPwa(installPrompt);
      });
    }

    return () => {
      isMounted = false;
    };
  }, [pwa]);

  useEffect(() => {
    // browser media querie
    let mediaQuerie = window.matchMedia("(max-width: 30.50em)");
    // true > 51.25em = 820px = laptop

    // adds double assurance of any bug when changing the screen size
    // bug: the change might not happened as expected if setMobileDeviceMedia is not
    // added before the function
    setMobileDeviceMedia(mediaQuerie.matches);
    const onHandlerMediaQuerie = (e) => {
      setMobileDeviceMedia(e.matches);
    };

    mediaQuerie.addEventListener("change", onHandlerMediaQuerie);

    return () => {
      mediaQuerie.removeEventListener("change", onHandlerMediaQuerie);
    };
  }, [mobileDeviceMedia]);

  useEffect(() => {
    // Disable right-click using useEffect hook
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "1em" }}
            >
              Loading.....
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<ShowCase />} />
            <Route path="application" element={<Application />} />
            <Route path="events" element={<Events />} />
            <Route path="upskilling" element={<UpSkilling />} />
            <Route path="streetwise" element={<StreetWise />} />
            <Route path="suitable" element={<Suitable />} />
            <Route path="launchpad" element={<LaunchPad />} />
            <Route path="contact" element={<ContactForm />} />
          </Routes>

          {!mobileDeviceMedia ? (
            <NagivationTopMenu data={pwa} />
          ) : (
            <MobileMenu data={pwa} />
          )}
        </Suspense>
      </Router>
    </>
  );
}

export default App;
